export type MapPosition = {
  shapeType: 'Circle' | 'Polygon'
  coordinates: number[]
  radius?: number
}

export type Artwork = {
  id: string
  url: string
  mapPosition?: MapPosition
}

export let artworks: Artwork[] = [
  {
    id: '1',
    url: 'https://colateralprodbasestorage.blob.core.windows.net/colateral-demo/artwork/b6e339a0-5e0b-11ee-bf91-6df171367b6b/0.png'
  },
  {
    id: '2',
    url: 'https://colateralprodbasestorage.blob.core.windows.net/colateral-usbank/itemPhotos/2ef743a0-5d44-11ee-8a0d-bd8b67777e6f/2ef743a0-5d44-11ee-8a0d-bd8b67777e6f/IMG_2204.jpeg-1695825794063-3010c9f0-5d44-11ee-8a0d-bd8b67777e6f.jpeg'
  }
]

export const updateArtwork = (updatedArtwork: Partial<Artwork>) => {
  artworks = artworks.map((artwork) => {
    if (updatedArtwork.id !== artwork.id) {
      return artwork
    }

    return {
      ...artwork,
      ...updatedArtwork,
    }
  })

}