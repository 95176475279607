import ImageLayer from 'ol/layer/Image.js'
import Map from 'ol/Map.js'
import Projection from 'ol/proj/Projection.js'
import Static from 'ol/source/ImageStatic.js'
import View from 'ol/View.js'
import { getCenter } from 'ol/extent.js'
import { RefObject } from 'react'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'

export const mapStyles = {
  Point: {
    'circle-radius': 5,
    'circle-fill-color': 'red',
  },
  LineString: {
    'circle-radius': 5,
    'circle-fill-color': 'red',
    'stroke-color': 'yellow',
    'stroke-width': 2,
  },
  Polygon: {
    'circle-radius': 5,
    'circle-fill-color': 'red',
    'stroke-color': 'yellow',
    'stroke-width': 2,
    'fill-color': 'rgba(255, 255, 0, 0.5)',
  },
  Circle: {
    'circle-radius': 5,
    'circle-fill-color': 'red',
    'stroke-color': 'blue',
    'stroke-width': 2,
    'fill-color': 'yellow',
  },
};

const getImageDimensions = (imageUrl: string): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      })

      img.remove()
    }
    img.src = imageUrl
  })
}

const calculateProjection = async (imageUrl: string) => {
  const imageDimensions = await getImageDimensions(imageUrl)
  // Map views always need a projection.  Here we just want to map image
  // coordinates directly to map coordinates, so we create a projection that uses
  // the image extent in pixels.
  const extent = [0, 0, imageDimensions.width, imageDimensions.height]
  const projection = new Projection({
    code: 'xkcd-image',
    units: 'pixels',
    extent: extent,
  })

  return {
    extent,
    projection,
  }
}

export const createMap = async (ref: RefObject<HTMLDivElement>, imageUrl: string) => {
  const { projection, extent } = await calculateProjection(imageUrl)

  const vectorSource = new VectorSource({wrapX: false});

const vector = new VectorLayer({
  source: vectorSource,
});

  const map = new Map({
    target: ref.current as HTMLElement,
    layers: [
      new ImageLayer({
        source: new Static({
          url: imageUrl,
          projection: projection,
          imageExtent: extent,
        }),
      }),
      vector
    ],
    view: new View({
      projection: projection,
      center: getCenter(extent),
      zoom: 2,
      maxZoom: 8,
    }),
  })

  return {map, vectorSource}
}
