import OpenLayersMap from 'ol/Map'
import { Select } from 'ol/interaction'
import { useCallback, useEffect, useRef, useState } from 'react'
import { createMap, mapStyles } from './createMap'
import React from 'react'
import Draw from 'ol/interaction/Draw.js'
import { Circle } from 'ol/geom'
import { Feature } from 'ol'
import VectorSource from 'ol/source/Vector'
import { SelectEvent } from 'ol/interaction/Select'
import { Artwork, MapPosition, artworks, updateArtwork } from './artworks'

interface Props {}

type MapState = { map: OpenLayersMap; vectorSource: VectorSource }
const imageUrl =
  'https://colateralprodbasestorage.blob.core.windows.net/colateral-demo/floorplan/58b5a570-594c-11ee-a313-2fa35bf45b3f/4f91e940_6a80fedd74dc7-Albufeira%20CC%20Algarve%20(1).png'

let mapInitStarted = false

const Map: React.FC<Props> = () => {
  const mapTargetElement = useRef<HTMLDivElement>(null)
  const mapRef = useRef<MapState>()
  const interactionRef = useRef<Draw | Select | undefined>()
  const [selectedArtwork, setSelectedArtwork] = useState<Artwork | undefined>()

  const selectHandler = (e: SelectEvent) => {
    const clickedFeature = e.selected[0]

    if (!clickedFeature) {
      setSelectedArtwork(undefined)
      return
    }

    const artworkId = clickedFeature.get('id')
    const artwork = artworks.find((artwork) => artwork.id === artworkId)
    if (!artwork) {
      return
    }

    setSelectedArtwork(artwork)
  }

  const addSelectInteraction = useCallback((map?: MapState) => {
    if (!map) {
      console.log('no map')
      return
    }

    if (!interactionRef) {
      console.log('no interaction')
      return
    }

    // @ts-ignore
    map.map.removeInteraction(interactionRef.current)

    // Create a new Select interaction
    const select = new Select()

    select.on('select', selectHandler)
    // Add it to your map
    map.map.addInteraction(select)
    interactionRef.current = select
    console.log('select added')
  }, [])

  const addDrawInteraction = useCallback((map?: MapState) => {
    if (!map) {
      console.log('no map')
      return
    }
    if (interactionRef.current) {
      map.map.removeInteraction(interactionRef.current)
    }

    const draw = new Draw({
      source: map.vectorSource,
      type: 'Circle',
      style: mapStyles.Circle,
    })

    draw.on('drawend', (e) => {
      if (!selectedArtwork) {
        return
      }

      const circle = e.feature.getGeometry() as Circle
      const center = circle.getCenter()
      const radius = circle.getRadius()

      const artworkPosition: MapPosition = {
        coordinates: center,
        radius,
        shapeType: 'Circle',
      }

      const updatedArtwork = {
        ...selectedArtwork,
        mapPosition: artworkPosition,
      }

      updateArtwork(updatedArtwork)

      addSelectInteraction(mapRef.current)

      drawArtworkOnMap(updatedArtwork)
    })

    map.map.addInteraction(draw)
    interactionRef.current = draw
    console.log('draw added')
  }, [addSelectInteraction, selectedArtwork])

  const drawArtworkOnMap = (artwork: Artwork) => {
    const map = mapRef.current
    if (!map) {
      return
    }
    if (!artwork.mapPosition) {
      return
    }

    const center = new Feature(new Circle(artwork.mapPosition.coordinates, artwork.mapPosition.radius))
    center.set('id', artwork.id)
    // add to source
    map.vectorSource.addFeature(center)
  }

  const initMap = useCallback(async () => {
    if (mapInitStarted) {
      console.log('map init already started')
      return
    }
    console.log('init map')
    mapInitStarted = true

    if (!mapRef.current) {
      mapRef.current = await createMap(mapTargetElement, imageUrl)
    }

    addSelectInteraction(mapRef.current)
  }, [addSelectInteraction])

  useEffect(() => {
    initMap()
  }, [initMap])

  useEffect(() => {
    if (!selectedArtwork) {
      return
    }

    if (selectedArtwork?.mapPosition) {
      return
    }

    addDrawInteraction(mapRef.current)
  }, [selectedArtwork, addDrawInteraction])

  return (
    <main className="app">
      <h1>Store plan as a map</h1>
      {!!selectedArtwork && <span>Placing artwork {selectedArtwork.id}</span>}
      <div ref={mapTargetElement} className="map"></div>
      <div>
        <h2>Artworks</h2>
        {artworks.map((artwork) => {
          return (
            <div key={artwork.id} className={`artwork-container ${artwork.id === selectedArtwork?.id ? 'artwork-container--selected' : ''}`}>
              <img className="artwork-image" src={artwork.url} alt={artwork.id} />
              {!artwork.mapPosition && <button onClick={() => setSelectedArtwork(artwork)}>Place on map</button>}
              {!!artwork.mapPosition && <span>Placed on map:<br/>{JSON.stringify(artwork.mapPosition)}</span>}
            </div>
          )
        })}
      </div>
    </main>
  )
}

export default Map
